header.navigation-lp {
  width: 100%;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 639px) {
      padding: 14px 0;
    }

    @media(min-width: 640px) {
      padding: 16px 0;
    }

  }

  .logo {

    img {

      @media(max-width: 639px) {
        height: 24px;
        max-height: 24px;
      }

      @media(min-width: 640px) {
        height: 48px;
        max-height: 48px;
      }
    }
  }
}