.file-upload-input {

  &.loaded {

    .file-input-wrapper {

      .camera-photo .icon-wrapper:before {
        background-color: $color-green-700;
        background-image: url(http://nn.pl/dam/ikony-przeformatowane/ikony-new/ok.svg);
      }
    }

    .files-list-wrapper {
      max-height: 500px;  
      padding: 28px 24px 24px;  
      // display: block;
    }
  }

  .file-input-wrapper {
    width: 100%;
    // min-height: 200px;
    
    position: relative;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(65, 65, 65, 0.1),
      0px 3px 10px rgba(65, 65, 65, 0.1), 0px 10px 20px rgba(65, 65, 65, 0.1);
    border-radius: 3px;

    @include breakpoint(medium) {
      display: flex;
    }

    &:before {
      position: absolute;
      content: "";
      width: calc(100% - 32px);
      height: calc(100% - 32px);
      top: 16px;
      left: 16px;
      border: 2px dashed $color-grey-light;
      border-radius: 3px;
    }

    .camera-photo {
      height: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      padding: 24px;

      .icon-wrapper {
        width: 120px;
        height: 120px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
          content: "";
          width: 48px;
          height: 48px;
          position: absolute;
          right: -10px;
          bottom: 10px;
          background-color: #ea650d;
          border-radius: 50%;
          background-image: url(http://nn.pl/dam/ikony-przeformatowane/ikony-new/upload.svg);
          background-size: 32px 32px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .cta-description {
      width: 100%;
      padding: 24px;
      text-align: center;

      @include breakpoint(medium) {
        text-align: left;
      }

      legend {
        margin: 0 auto;
        font-family: $nngrotesk-text-regular;
        font-size: 16px;
        line-height: 1.5;
        width: 100%;
        

        .legend-header {
          display: block;
          margin-bottom: 8px;
        }

        .input-tip {
          display: block;
          margin-top: 8px;
          width: fit-content;        
          

          @include breakpoint(small only) {
              margin: 8px auto 0;
          }
        }
      }

      label {
        margin-top: 16px;
        padding: 4px 24px;
        font-size: 18px;
        line-height: 1.33;
      }

      input {
        display: none;
      }
    }
  }

  .files-list-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: all 0.7s ease-in-out;  
    padding: 0 24px;
    // display: none;
    background-color: $color-snow-white;

    .list-element {
      position: relative;
      padding-bottom: 12px;
      margin-bottom: 16px;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-white;
      }
    }

    .files-list-header {
      display: flex;
      align-items: center;

      .files-counter {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        background-color: $color-orange-medium;
        border-radius: 24px;
        color: $color-white;
        font-size: 10px;
        font-weight: 700;
        font-family: $nngrotesk-text-bold;
      }
    }

    .files-list {
      margin-bottom: 0;

      .list-element {
        display: flex;
        align-items: center;

        &:last-of-type {
          margin-bottom: 0;
        }

        .file-data {
          width: 100%;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 32px;
          color: $color-grey-dark;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 3L14 8L19 8M14 3L5 3L5 21L19 21L19 8M14 3L19 8M15.5 12L8.5 12M15.5 16L8.5 16" stroke="%23404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          }

          .file-name {
            position: relative;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            word-break: break-all;
            white-space: normal;
            overflow-wrap: break-word;
            display: inline-block;
            max-width: 70%; 
          }

          .file-meta {
            font-size: 12px;
            line-height: 1.5;

            .weight {
              position: relative;
              display: inline-block;
              padding-left: 12px;

              &:before {
                content: "";
                width: 2px;
                height: 2px;
                position: absolute;
                top: 8px;
                left: 5px;
                background-color: $color-grey-dark;
                border-radius: 2px;
              }
            }
          }
        }

        .button-remove {
          margin-left: 16px;
          color: $color-grey-dark;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.5;
          text-decoration: underline;

          &:hover {
            color: $color-orange;
          }
        }
      }
    }
  }

  &.error {
    .validation-message {
      margin-top: 8px;
    }
  }

  &.loaded {
    .reset-custom-file {
      display: block;
    }
  }
}
