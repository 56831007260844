// form-elements.scss and Components DS styles are used here and need to be included in the main controller

.form-paperless {
  @import "./partials/conditional-sections.scss";
  @import "./partials/file-upload-input.scss";

  padding-bottom: 64px;

  // typography elements (extending/overwriting DS defaults)
  .section-header-big {
    margin-bottom: 24px;
  }

  .section-header,
  .section-header-small {
    margin-bottom: 16px;
  }

  .list-simple {
    margin-bottom: 0;

    li:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  .info-box {
    max-width: unset;
  }

  // header
  .form-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    padding: 28px 24px;
    border-bottom: 1px solid $color-grey-white;

    .form-header-btn {
      position: absolute;
      left: 24px;
      top: 28px;

      &:before {
        transform: translateY(-40%) rotate(180deg);
      }

      &:hover,
      &:focus {
        &:before {
          left: -0.375rem;
        }
      }
    }

    .form-header-status {
      font-weight: 700;
      text-align: center;

      @include breakpoint(large up) {
        max-width: calc(578px - 96px);
      }

      @include breakpoint(xlarge up) {
        max-width: calc(578px - 32px);
      }

      @include breakpoint(medium down) {
        margin-top: 38px;
      }

      span {
        text-transform: lowercase;
      }
    }
  }

  .custom-checkbox input[type=checkbox]+label:after {
      line-height: .9375rem;
  }


  .form-wrapper {
    padding: 0 16px;
    margin: 0 auto;

    @include breakpoint(medium up) {
      width: 578px;
    }
  }

  // stepper
  .step-wrapper {
    // steps general
    .step {
      display: none;
      opacity: 0;
      transition: opacity .6s ease;

      &.active {
        display: block;
        transition: opacity .6s ease;
      }
    }

    .step-case {
      margin-bottom: 32px;
    }

    .step-footer {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;

      // initial values (step 1)
      [data-step-prev], [data-step-main] {
        display: none;
      }
      [data-step-submit], [data-step-reset] {
        display: none;
      }
    }

    //steps specific
    .step-1 {
      opacity: 1; // initial

      .info-box {
        .text-simple {
          a {
            display: block;
            margin-top: 8px;
          }
        }
      }
    }

    .step-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      // display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(6px);
      z-index: 100;
      opacity: 0;
      transition: opacity .6s ease;

      &.active {
        display: flex;
        transition: opacity .6s ease;
      }

      .step-modal-inner {
        position: relative;
        padding: 100px 16px 48px;
        background-color: $color-white;

        @include breakpoint (large up) {
          padding: 100px 130px 48px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 64px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $color-grey-white;

          @include breakpoint (large up) {
            top: 80px;
          }
        }

        // weird class/listener conflict with app.js - to inspect... #csi
        .hide-modal {
          position: absolute;
          z-index: 1;
          cursor: pointer;
      
          @include breakpoint(medium down) {
            top: 20px;
            right: 20px;
          }
      
          @include breakpoint(large) {
            top: 33px;
            right: 53px;
          }
      
          &:hover {
            transform: scale(1.05);
          }
        }

        .disclaimer {
          align-items: flex-start;
          padding: 0;

          @include breakpoint (large up) {
            width: 640px;
          }

          .text-simple {
            margin-bottom: 24px;
          }

          .buttons-group {

            .btn-group-item:nth-child(2) {
              @include breakpoint (small down) {
                display: block;
                margin: 0;
              }
              @include breakpoint (medium up) {
                margin-left: 16px;
              }
            }
          }          
        }
      }
    }

    .step-summary {

      .claim-summary {
        font-size: 16px;
        line-height: 1.5;  
        margin-bottom: 1rem;

        .summary-row {
          display: flex;
          padding: 16px 0;
          border-bottom: 1px solid $color-snow-white;

          .summary-key {
            width: 230px;
            min-width: 230px;
          }

          .summary-value {
            width: 100%;
            font-weight: 700;
          }

          &:has(.summary-value:empty) {
            display: none;
          }
        }
        
        & > .summary-row:first-of-type {
          border-top: 1px solid $color-grey-light;
        }

        .no-data {
          color: $color-grey-medium;
        }
      }
    }

    .step-confirmation {
      .claim-confirmation {

        @keyframes scaleUpDown {
          0% {
            transform: scale(1); /* Start at normal size */
          }
          50% {
            transform: scale(1.1); /* Scale up to 110% */
          }
          100% {
            transform: scale(1); /* Scale back to normal size */
          }
        }

        &.active {
          .animate-indicator:before {
            animation: scaleUpDown 0.6s ease-in-out 0.5s forwards; 
          }
        }

        .confirmation-box {
          position: relative;
          padding: 24px 16px 24px 72px;
          background-color: $color-snow-white;
          border-radius: 3px;

          .claim_reason {
            text-transform: lowercase;
          }

          .section-header-small {
            margin-bottom: 8px;
          }

          &:not(:last-of-type) {
            margin-bottom: 16px;
          }

          &:before {
            width: 48px;
            height: 48px;
            position: absolute;
            top: 24px;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-snow-white;
            border-radius: 48px;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.4;
          }

          &:first-of-type {
            background-color: $color-green-00;

            .section-header-small {
              color: $color-green-700;
            }

            &:before {
              content: "";
              background-color: $color-green-700;
              background-image: url(http://nn.pl/dam/ikony-przeformatowane/ikony-new/ok-white.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: 60%;
            }

            &:not(:last-child) {
              &:after {
                content: "";
                width: 1px;
                height: 100px;
                position: absolute;
                top: 72px;
                left: 24px;
                background-color: $color-green-500;
                z-index: 2;
              }
            }
          }

          &:nth-of-type(2) {
            &:before {
              content: "2";
              border: 2px solid $color-blue-500;
              color: $color-blue-500;
              z-index: 3;
            }

            &:not(:last-child) {
              &:after {
                content: "";
                width: 1px;
                height: 140px;
                position: absolute;
                top: 72px;
                left: 24px;
                background-color: $color-grey-white;
                z-index: 2;
              }
            }
          }

          &:nth-of-type(3) {
            &:before {
              content: "3";
              border: 2px solid $color-purple-500;
              color: $color-purple-500;
              z-index: 4;
            }
          }
        }
      }
    }
  }

  // form error
  .form-error {
    width: calc(100% - 32px);
    position: fixed;
    top: 16px;
    left: 16px;
    display: none;
    // display: flex;
    align-items: flex-start;
    padding: 16px;
    background-color: $color-error-red;
    border-radius: 3px;
    opacity: 0;
    transition: opacity .6s ease;
    box-shadow: 0 0 4px rgba(65,65,65,.1),0 8px 16px rgba(65,65,65,.1),0 16px 32px rgba(65,65,65,.2);

    @include breakpoint(medium) {
      width: 432px;
      top: unset;
      bottom: 32px;
      left: 48px;
      transform: translateX(-480px);
    }

    &.active {
      display: flex;
      opacity: 1;
      transition: opacity .6s ease;

      @include breakpoint(medium) {
        transform: translateX(0px);
      }
    }

    img {
      width: 32px;
      margin-right: 16px;;
    }

    .form-error-message {
      color: $color-white;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
    }
  }

  .button-file .display-name {
    font-size: 16px;
    font-family: NNNittiGrotesk-TextRegular;
    font-weight: 700;
  }

  .documents-list {
    display: none;

    &.active {
      display: flex;
    };

    .list-simple {
      margin-bottom: 8px;
    }
  }

  .document-item {
    display: none;

    &.always {
      display: block;
    };
    
    &.active {
      display: block;
    };
  }

  .info-ofe {
    display: none;
    margin-top: 24px;
    
    &.active {
      display: flex;
    };
  }
  
  .form-confirmation {
    display: none;
    
    &.active {
      display: block;
    };
  }
  
  .applications-container {
    display: none;
    
    &.active {
      display: block;
    };

    .hidden {
      display: none;
    }
    .list-simple {
      margin-top: 8px;
    }

    .list-item {
      margin-bottom: 8px;
    }
  }
  
  .list-simple {
    .list-item {
      font-size: 16px;
    }
  }

  .radio-input-wrapper {
    display: flex;
    margin-bottom: 16px;

    .custom-tooltip {
      .tooltip-trigger {
        width: 24px;
        height: 24px;

        .icon-svg {
          width: 24px;
          height: 24px;  
        }
      }
    }
  }
  
  .step-forms .form-list {
    display: block;

    .form-file {
      display: none;    

      &.active {
        display: block;
      };
    }
  }

  .step-forms .upload-form-list {
    display: block;

    .file-upload-input {
      display: none;    
      margin-bottom: 24px;

      &.always {
        display: block;
      };

      &.active {
        display: block;
      };
    }
  }

  .step-documents .documents-list {
    display: block;

    .file-upload-input {
      display: none;    
      margin-bottom: 24px;

      &.always {
        display: block;
      };

      &.active {
        display: block;
      };
    }
  }
  
  .step-summary .claim-summary .summary-row.summary-optional {
    display: none;

    &.active {
      display: flex;
    }
  }

  .show-list {
    display: block;
    position: relative;  
    min-width: 20px;
    height: 20px;

    &::after {
      content: "";
      width: 14px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 5px;
      background: transparent url(https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-down.svg) 50% no-repeat;
      background-size: auto;
      background-size: contain;
      z-index: 1;
      transition: .2s;
      pointer-events: none;
    }

    &.more {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .email-confirmation {
    display: none;

    &.show { 
      display: block;
    }
  }

  .added-documents {
    display: none;

    &.active { 
      display: block;
    }
  
    .document {
      display: flex;
      margin-left: 32px;
      border-bottom: 1px solid #F2F2F2;
      gap: 24px;

      
    }

    .document-name {
      padding: 16px 0;
      font-size: 16px;
      line-height: 1.5;
      flex-basis: 172px;
    }

    .file-list {
      flex-grow: 1;

      .file {
        display: flex;
        gap: 24px;
        justify-content: space-between
      }

      .file-name {
        padding: 16px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
      }

      .file-format {
        flex-basis: 30px;
        padding: 16px 0;
      }

    }    
  }

  .text-optional {      
    display: none;

    &.active { 
      display: block;
    }
  }

  [data-complete-documents] {
    display: none;

    &.active {
      display: block;
    }
  }

  [data-documents-info] {
    display: none;

    &.active {
      display: block;
    }
  }

  .without-pesel {
    display: none;

    &.active {
      display: block;
    }
  }

  // utils
  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .mb-0 {
    margin-bottom: 0;
  }
}

