footer.footer-lp {
  background-color: #fff;
  border-top: 2px solid #EE7F00;

  @media(max-width: 639px) {
    padding: 26px 1.25rem 68px;
  }

  @media(min-width: 640px) {
    padding: 36px 1.25rem;
  }

  @media(min-width: 1024px) {
    padding: 36px 0;
  }

  .footer-top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .logo {
      height: 34px;
      max-height: 34px;
    }
  }

  .footer-middle-block {

    @media(min-width: 1180px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    .footer-links {
      margin: 0;

      @media(max-width: 1179px) {
        margin-bottom: 16px;
      }

      li {
        list-style-type: none;

        @media(max-width: 639px) {
          margin-bottom: 8px;
        }

        @media(min-width: 640px) {
          display: inline-block;
          margin-right: 16px;
        }

        a {
          font-size: 16px;
          line-height: 1.25;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .footer-contact {
      margin-bottom: 8px;
      color: #767676;
      font-size: 16px;
      line-height: 1.25;

      br {
        @media(min-width: 740px) {
          display: none;
        }
      }

      @media(min-width: 1180px) {
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        margin: 0 12px;
        color: #767676;
        font-size: 16px;
        line-height: 1.25;

        &:nth-of-type(1) {
          @media(max-width: 739px) {
            margin-left: 0;
          }
        }

        &:nth-of-type(2) {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: -17px;
            top: 5px;
            width: 8px;
            height: 8px;
            background-color: #EE7F00;
            border-radius: 8px;
          }
        }

        &:hover {
          color: #EE7F00;
        }
      }

    }

    .footer-legal {
      color: #767676;
      font-size: 12px;
      line-height: 1.33;
      margin: 0;

      @media(min-width: 1180px) {
        display: none;
      }
    }
  }

  .footer-bottom-block {
    margin-top: 16px;

    @media(min-width: 1180px) {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }

    .footer-legal {
      color: #767676;
      font-size: 12px;
      line-height: 1.33;
      margin: 0;

      &:nth-of-type(2) {

        @media(max-width: 1179px) {
          display: none;
        }
      }
    }
  }
}